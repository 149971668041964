import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../util/Alert";
import MaleImg from "../../../../assets/images/male.png";
import FemaleImg from "../../../../assets/images/female.png";
import { baseURL } from "../../../util/config";
import {
  studentLeaveDelete,
  studentLeaveGet,
  studentLeaveStatusUpdate,
} from "../../../../redux/slice/studentLeaveSlice";
import StudentLeaveAdd from "./StudentLeaveAdd";
import StudentLeaveDetails from "./StudentLeaveDetails";

const PendingStudentLeave = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const { studentLeave, leaveTotal } = useSelector(
    (state) => state.studentLeave
  );

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const payload = {
    leaveStatus: 1,
    page,
    limit: rowsPerPage,
    search,
  };

  // Server Get
  useEffect(() => {
    dispatch(studentLeaveGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(studentLeaveGet({ ...payload, command: true }));
  }, []);

  useEffect(() => {
    setData(studentLeave);
  }, [studentLeave]);

  const handleDeleteLeave = async (id) => {
    const data = warning("", "Remove Leave");
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          dispatch(studentLeaveDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleLeaveStatus = async (id, status) => {
    let data;
    if (status === 2) {
      data = warning("", "Accept Leave", "Accept", "ri-check-line", "success");
    } else if (status === 3) {
      data = warning(
        "",
        "Reject Leave",
        "Reject",
        "ri-close-circle-line",
        "second"
      );
    }
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          dispatch(
            studentLeaveStatusUpdate({ leaveId: id, leaveStatus: status })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const studentLeaveTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Name",
      body: "studentName",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span>
            <div className="height-30 rounded-5 width-30 bg-second m20-right overflow-hidden">
              <img
                src={
                  row?.image
                    ? baseURL + row?.image
                    : row?.gender == "male"
                    ? MaleImg
                    : FemaleImg
                }
                alt=""
                height={`100%`}
              />
            </div>
          </span>
          <span className="m15-right">{row?.studentName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    {
      Header: "Form No",
      body: "formNo",
      bText: "#",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Month / Year",
      bBody: "month",
      bText: "/",
      bTextClass: "text-second",
      body: "year",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Leave Date",
      bBody: "leaveFrom",
      bText: "to",
      bTextClass: "text-second",
      body: "leaveTo",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Day(Holi..",
      body: "allDays",
      aBody: "holidays",
      aBodyClass: "text-second",
      aText: "(",
      aTextClass: "text-second",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Days",
      body: "totalDays",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Permission",
      body: "-",
      Cell: ({ row }) =>
        row.status == 1 ? (
          <>
            <ActionButton
              className="bg-success-light text-success  border-success-1  m5-right "
              icon={`ri-check-line`}
              onClick={() => {
                handleLeaveStatus(row._id, 2);
              }}
            />
            <ActionButton
              className="bg-second-light text-second  border-second-1  m5-right actionClick"
              icon={`ri-close-line`}
              onClick={() => {
                handleLeaveStatus(row._id, 3);
              }}
            />
          </>
        ) : row.status == 2 ? (
          <button
            className={`bg-success-light text-success border-success-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
          >
            Accepted
          </button>
        ) : (
          row.status == 3 && (
            <button
              className={`bg-danger-light text-danger border-danger-1 p20-x p4-y fs-12 text-capitalize p6-x p3-y fs-10 fw-600 rounded-3`}
            >
              Rejected
            </button>
          )
        ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      body: "-",
      Cell: ({ row }) => (
        <>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-darkGray-1  m5-right "
            icon={`ri-pencil-line`}
            onClick={() => {
              dispatch(openDialog({ type: "pendingLeave", data: { ...row } }));
            }}
          />
          <ActionButton
            className="bg-danger-light text-danger  border-danger-1  m5-right actionClick"
            icon={`ri-delete-bin-6-line`}
            onClick={() => {
              handleDeleteLeave(row._id);
            }}
          />
          <ActionButton
            className="bg-blue-light text-blue  border-blue-1  m5-right "
            icon={`ri-information-line`}
            onClick={() => {
              dispatch(
                openDialog({ type: "staffLeaveDetails", data: { ...row } })
              );
            }}
          />
        </>
      ),
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title
            name={"Pending Student Leave"}
            icon={`ri-user-shared-2-line`}
          />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={studentLeaveTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-darkGray-2 m20-left`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "pendingLeave" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={studentLeaveTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={leaveTotal}
      />

      {dialogue && dialogueType === "pendingLeave" && <StudentLeaveAdd />}
      {dialogue && dialogueType === "staffLeaveDetails" && (
        <StudentLeaveDetails />
      )}
    </>
  );
};

export default PendingStudentLeave;
