import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  staff: [],
  passShow: "",
  staffTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const staffGet = createAsyncThunk("staff/show", async (payload) => {
  return apiInstance.get(
    `staff/show?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
  );
});
export const showPassword = createAsyncThunk(
  "staff/showPassword",
  async (payload) => {
    return apiInstance.get(`staff/showPassword?staffId=${payload}`);
  }
);
export const staffAdd = createAsyncThunk("staff/create", async (payload) => {
  return apiInstance.post("staff/create", payload);
});
export const staffUpdate = createAsyncThunk("staff/update", async (payload) => {
  return apiInstance.patch(`staff/update?id=${payload.id}`, payload.formData);
});
export const staffDelete = createAsyncThunk("staff/delete", async (id) => {
  return apiInstance.delete(`staff/delete?staffId=${id}`);
});
// 1:block 2:HR 3:Receptionist
export const staffSwitch = createAsyncThunk(
  "staff/staffToggleSwitch",
  async (payload) => {
    return apiInstance.put(
      `staff/staffToggleSwitch?staffId=${payload.staffId}&status=${payload.status}`
    );
  }
);

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // staffGet
    builder.addCase(staffGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(staffGet.fulfilled, (state, action) => {
      state.staff = action.payload.staff;
      state.staffTotal = action.payload.staffTotal;
      state.isSkeleton = false;
    });
    builder.addCase(staffGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(showPassword.fulfilled, (state, action) => {
      state.passShow = action.payload.staff;
      state.isSkeleton = false;
    });

    // staffCreate
    builder.addCase(staffAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffAdd.fulfilled, (state, action) => {
      state.staff.unshift(action.payload.staff);
      state.isLoading = false;
    });
    builder.addCase(staffAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffUpdate
    builder.addCase(staffUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const staffIdx = state.staff.findIndex(
          (staff) => staff._id === action.payload.staff._id
        );
        if (staffIdx !== -1) {
          state.staff[staffIdx] = {
            ...state.staff[staffIdx],
            ...action.payload.staff,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffDelete
    builder.addCase(staffDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.staff = state.staff.filter(
          (staff) => staff._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffSwitch
    builder.addCase(staffSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffSwitch.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const staffIdx = state.staff.findIndex(
          (staff) => staff._id === action.payload.staff._id
        );
        if (staffIdx !== -1) {
          state.staff[staffIdx] = {
            ...state.staff[staffIdx],
            ...action.payload.staff,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default staffSlice.reducer;
