import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  banner: [],
  isLoading: false,
  isSkeleton: false,
};
export const bannerGet = createAsyncThunk(
  "banner/bannerGet",
  async (payload) => {
    return apiInstance.get(`banner/bannerGet?type=admin`);
  }
);
export const bannerAdd = createAsyncThunk(
  "banner/bannerAdd",
  async (payload) => {
    return apiInstance.post("banner/bannerAdd", payload);
  }
);
export const bannerUpdate = createAsyncThunk(
  "banner/updateBanner",
  async (payload) => {
    debugger;
    return apiInstance.patch(
      `banner/updateBanner?bannerId=${payload.bannerId}`,
      payload.formData
    );
  }
);
export const bannerDelete = createAsyncThunk(
  "banner/bannerDelete",
  async (bannerId) => {
    return apiInstance.delete(`banner/bannerDelete?bannerId=${bannerId}`);
  }
);

export const bannerAction = createAsyncThunk(
  "banner/enableDisableBanner",
  async (payload) => {
    return apiInstance.put(`banner/enableDisableBanner?bannerId=${payload}`);
  }
);

const bannerSlice = createSlice({
  name: "bannerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // bannerGet
    builder.addCase(bannerGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(bannerGet.fulfilled, (state, action) => {
      state.banner = action.payload.banner;
      state.isSkeleton = false;
    });
    builder.addCase(bannerGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // bannerCreate
    builder.addCase(bannerAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(bannerAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.banner.unshift(action.payload.banner);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(bannerAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // bannerUpdate
    builder.addCase(bannerUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(bannerUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const bannerIdx = state.banner.findIndex(
          (banner) => banner._id === action.payload.banner._id
        );
        if (bannerIdx !== -1) {
          state.banner[bannerIdx] = {
            ...state.banner[bannerIdx],
            ...action.payload.banner,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(bannerUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // bannerDelete
    builder.addCase(bannerDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(bannerDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.banner = state.banner.filter(
          (banner) => banner._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(bannerDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    // bannerAction
    builder.addCase(bannerAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(bannerAction.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const bannerIdx = state.banner.findIndex(
          (banner) => banner._id === action.payload.banner._id
        );
        if (bannerIdx !== -1) {
          state.banner[bannerIdx] = {
            ...state.banner[bannerIdx],
            ...action.payload.banner,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(bannerAction.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default bannerSlice.reducer;
