import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { SetDevKey, apiInstance, setToken } from "../../api/axiosApi";
import { key } from "../../component/util/config";
import { setToast } from "../../component/extra/toast";
import Cookies from "universal-cookie";

const initialState = {
  admin: {},
  isAuth: false,
  isLoading: false,
};

export const login = createAsyncThunk("admin/login", async (payload) => {
  return apiInstance.post("admin/login", payload);
});

export const updateImage = createAsyncThunk(
  "admin/updateImage",
  async (payload) => {
    return apiInstance.put("admin/updateImage", payload);
  }
);

export const updateAdminData = createAsyncThunk(
  "admin/update",
  async (payload) => {
    return apiInstance.patch("admin/update", payload);
  }
);
export const updateAdminPassword = createAsyncThunk(
  "admin/updatePassword",
  async (payload) => {
    return apiInstance.patch("admin/updatePassword", payload);
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      const cookieToken = action.payload.token;
      let token_;
      if (typeof cookieToken == "object") {
        token_ = action.payload.token;
      } else {
        token_ = JSON.parse(action.payload.token);
      }
      state.admin = token_;
      state.isAuth = true;
      setToken(action.payload.tokenSil);
      SetDevKey(key);
    },
    logout(state, action) {
      const cookies = new Cookies(null, { path: "/" });
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      sessionStorage.removeItem("tokenSil");
      cookies.remove("token");
      cookies.remove("key");
      cookies.remove("isAuth");
      cookies.remove("tokenSil");
      state.admin = {};
      state.isAuth = false;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Admin Login
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.isAuth = true;
        state.token = action.payload.token;

        SetDevKey(key);
        setToken(action.payload.token);
        // Set Session
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSil",
          action.payload.token ? action.payload.token : undefined
        );
        sessionStorage.setItem("key", key ? key : undefined);
        sessionStorage.setItem("isAuth", true);
        // Set Cookies
        const cookies = new Cookies(null, { path: "/" });
        cookies.set("token", token_ ? JSON.stringify(token_) : undefined, {
          path: "/",
          maxAge: 2592000 * 6,
        });
        cookies.set(
          "tokenSil",
          action.payload.token ? action.payload.token : undefined,
          { path: "/", maxAge: 2592000 * 6 }
        );
        cookies.set("key", key ? key : undefined, {
          path: "/",
          maxAge: 2592000 * 6,
        });
        cookies.set("isAuth", true, { path: "/", maxAge: 2592000 * 6 });

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateImage.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateImage.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSil",
          action.payload.token ? action.payload.token : undefined
        );

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateImage.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateAdminData.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSil",
          action.payload.token ? action.payload.token : undefined
        );

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });

    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSil",
          action.payload.token ? action.payload.token : undefined
        );

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });
  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
