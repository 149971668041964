import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  staffLeave: [],
  staffLeaveYear: [],
  staffLeaveTotal: 0,
  leavesAll: [],
  leaveTotal: 0,
  findStaff: "",
  isLoading: false,
  isSkeleton: false,
};

export const staffLeaveGet = createAsyncThunk(
  "staffLeave/showLeave",
  async (payload) => {
    return apiInstance.get(
      `staffLeave/showLeave?staffId=${payload?.staffId}&year=${payload?.year}`
    );
  }
);
export const leaveGet = createAsyncThunk(
  "staffLeave/showStatusLeave",
  async (payload) => {
    return apiInstance.get(
      `staffLeave/showStatusLeave?leaveStatus=${payload?.leaveStatus}&page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
    );
  }
);
export const findStaffById = createAsyncThunk(
  "staffLeave/staffFindById",
  async (staffCode) => {
    return apiInstance.get(`staffLeave/staffFindById?staffCode=${staffCode}`);
  }
);
export const staffLeaveCreate = createAsyncThunk(
  "staffLeave/createLeave",
  async (payload) => {
    return apiInstance.post(`staffLeave/createLeave`, payload);
  }
);
export const staffLeaveUpdate = createAsyncThunk(
  "staffLeave/updateLeave",
  async (payload) => {
    return apiInstance.patch(
      `staffLeave/updateLeave?staffLeaveId=${payload.staffLeaveId}`,
      payload.payload
    );
  }
);
export const staffLeaveStatusUpdate = createAsyncThunk(
  "staffLeave/updateStatus",
  async (payload) => {
    return apiInstance.put(
      `staffLeave/updateStatus?leaveId=${payload.leaveId}&leaveStatus=${payload.leaveStatus}`
    );
  }
);
export const staffLeaveDelete = createAsyncThunk(
  "staffLeave/deleteLeave",
  async (payload) => {
    return apiInstance.delete(`staffLeave/deleteLeave?staffLeaveId=${payload}`);
  }
);

const staffLeaveSlice = createSlice({
  name: "staffLeaveSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // staffLeaveGet
    builder.addCase(staffLeaveGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(staffLeaveGet.fulfilled, (state, action) => {
      state.staffLeave = action.payload.staffLeave;
      state.staffLeaveYear = action.payload.staffLeaveYear;
      state.isSkeleton = false;
    });
    builder.addCase(staffLeaveGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // leaveGet
    builder.addCase(leaveGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(leaveGet.fulfilled, (state, action) => {
      state.leavesAll = action.payload.leavesAll;
      state.leaveTotal = action.payload.leaveTotal;
      state.isSkeleton = false;
    });
    builder.addCase(leaveGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // findStaffById
    builder.addCase(findStaffById.fulfilled, (state, action) => {
      action.payload.status
        ? (state.findStaff = action?.payload?.findStaff)
        : (state.findStaff = "");
    });

    // staffLeaveCreate
    builder.addCase(staffLeaveCreate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffLeaveCreate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.leavesAll = [...state.leavesAll, ...action.payload.staffLeave];
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffLeaveCreate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffLeaveUpdate
    builder.addCase(staffLeaveUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffLeaveUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const staffLeaveIdx = state?.leavesAll?.findIndex(
          (staffLeave) => staffLeave._id === action.payload.staffLeave._id
        );
        if (staffLeaveIdx !== -1) {
          state.leavesAll[staffLeaveIdx] = {
            ...state.leavesAll[staffLeaveIdx],
            ...action.payload.staffLeave,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffLeaveUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffLeaveStatusUpdate
    builder.addCase(staffLeaveStatusUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffLeaveStatusUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const staffLeaveIdx = state?.leavesAll?.findIndex(
          (staffLeave) => staffLeave._id === action.payload.leaveStatus._id
        );
        if (staffLeaveIdx !== -1) {
          state.leavesAll[staffLeaveIdx] = {
            ...state.leavesAll[staffLeaveIdx],
            ...action.payload.leaveStatus,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffLeaveStatusUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // staffLeaveDelete
    builder.addCase(staffLeaveDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(staffLeaveDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.leavesAll = state?.leavesAll?.filter(
          (staffLeave) => staffLeave._id !== action.meta.arg
        );
        state.leaveTotal -= 1;
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(staffLeaveDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default staffLeaveSlice.reducer;
