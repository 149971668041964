import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigator = (props) => {
  const location = useLocation();

  const { name, path, navIcon, onClick } = props;

  console.log("props", props.children);

  console.log("props---", props?.children?.props?.children[1]);

  let isActiveTag = false;
  if (props?.children?.props?.children[1]) {
    const mainPath = props?.children?.props?.children[1];
    isActiveTag = mainPath.some((obj) => obj.props.path === location.pathname);
  }

  return (
    <li
      onClick={onClick}
      key={`navHade`}
      className={`${path ? "activeClick" : "deactiveClick"} ${
        (isActiveTag || location.pathname === path) && "activeMasterMenu"
      }`}
    >
      <Link
        to={{ pathname: path }}
        className={`${location.pathname === path && "activeMenu"} betBox`}
      >
        <div className="betBox">
          {navIcon && <i className={`${navIcon}`}></i>}
          <span className="text-capitalize text-overflow-1 ">{name}</span>
        </div>
        {props?.children && <i className="ri-arrow-right-s-line  fs-18 m0"></i>}
      </Link>
      {/* If Submenu */}
      {props.children}
    </li>
  );
};

export default Navigator;
