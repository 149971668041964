import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Textarea } from "../../../extra/Input";
import Button from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { findStaffById } from "../../../../redux/slice/staffLeaveSlice";
import {
  studentLeaveAdd,
  studentLeaveGet,
  studentLeaveUpdate,
} from "../../../../redux/slice/studentLeaveSlice";
import { findStudentByFormNo } from "../../../../redux/slice/studentSlice";
import { setToast } from "../../../extra/toast";
import DialogBox from "../../../extra/DialogBox";

const StudentLeaveAdd = () => {
  const { dialogueData, dialogueText } = useSelector((state) => state.dialogue);
  const { findStudent } = useSelector((state) => state.student);
  const dispatch = useDispatch();

  let studentName = findStudent.studentName || "";
  let student_id = findStudent._id || "";
  let formNo = findStudent.formNo || "";
  useEffect(() => {
    if (dialogueData || dialogueText) {
      editData(dialogueData || dialogueText);
      dispatch(
        findStudentByFormNo(dialogueData?.formNo || dialogueText?.formNo)
      );
    }
    dispatch(findStudentByFormNo(""));
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addLeave = submitData(e);
    if (addLeave) {
      let payload = {
        ...addLeave,
        studentId: student_id,
        formNo,
      };
      try {
        let response;
        if (dialogueData) {
          response = await dispatch(
            studentLeaveUpdate({ payload, studentLeaveId: dialogueData._id })
          ).unwrap();
        } else {
          response = await dispatch(studentLeaveAdd(payload)).unwrap();
          if (response.status) {
            const payload = {
              leaveStatus: 1,
              page: "",
              limit: "",
              search: "",
            };
            dispatch(studentLeaveGet({ ...payload, command: false }));
          }
        }
        if (response.status) {
          dispatch(closeDialog());
        } else {
          setToast(response.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleFindStudent = (datas) => {
    dispatch(findStudentByFormNo(datas));
  };

  return (
    <DialogBox
      id={`studentLeaveForm`}
      title={`Student Leave Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-md-6 col-12">
          <Input
            type={`text`}
            id={`formNo`}
            name={`formNo`}
            label={`Form No`}
            errorMessage={`Enter Form No`}
            onKeyUp={(e) => {
              handleFindStudent(e.target.value);
            }}
            disabled={(dialogueData || dialogueText) && true}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <Input
            type={`text`}
            id={`studentName`}
            name={`studentName`}
            label={`Student Name`}
            errorMessage={`Enter Student Name`}
            disabled={(dialogueData || dialogueText) && true}
            value={studentName}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`date`}
            id={`leaveFrom`}
            name={`leaveFrom`}
            label={`Leave From`}
            errorMessage={`Enter Leave From`}
            minValue={
              dialogueData?.thisMonthStart
                ? dialogueData?.thisMonthStart
                : false
            }
            maxValue={
              dialogueData?.thisMonthEnd ? dialogueData?.thisMonthEnd : false
            }
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`date`}
            id={`leaveTo`}
            name={`leaveTo`}
            label={`Leave To`}
            errorMessage={`Enter Leave To`}
            minValue={
              dialogueData?.thisMonthStart
                ? dialogueData?.thisMonthStart
                : false
            }
            maxValue={
              dialogueData?.thisMonthEnd ? dialogueData?.thisMonthEnd : false
            }
          />
        </div>
        <div className="col-12">
          <Textarea
            row={4}
            type={`date`}
            id={`description`}
            name={`description`}
            label={`Description`}
            errorMessage={`Enter Description`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default StudentLeaveAdd;
