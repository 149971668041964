import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  installment: [],
  installmentCount: 0,
  receipts: [],
  receiptsCount: 0,
  remindingFee: [],
  remindingFeeCount: 0,
  isLoading: false,
  isSkeleton: false,
};
export const installmentGet = createAsyncThunk(
  "installment/showInstallment",
  async (payload) => {
    return apiInstance.get(`installment/showInstallment?studentId=${payload}`);
  }
);
export const installmentCreateSingle = createAsyncThunk(
  "installment/createSingleInstallment",
  async (payload) => {
    return apiInstance.post(`installment/createSingleInstallment`, payload);
  }
);
export const installmentCreateMultiple = createAsyncThunk(
  "installment/createInstallment",
  async (payload) => {
    return apiInstance.post(`installment/createInstallment`, payload);
  }
);
export const installmentUpdate = createAsyncThunk(
  "installment/editInstallment",
  async (payload) => {
    return apiInstance.patch(
      `installment/editInstallment?installmentId=${payload.installmentId}`,
      payload.details
    );
  }
);
export const installmentPay = createAsyncThunk(
  "installment/payInstallment",
  async (payload) => {
    return apiInstance.put(
      `installment/payInstallment?installmentId=${payload.installmentId}&isPay=${payload.isPay}`
    );
  }
);
export const installmentDelete = createAsyncThunk(
  "installment/deleteInstallment",
  async (payload) => {
    return apiInstance.delete(
      `installment/deleteInstallment?installmentId=${payload.installmentId}`
    );
  }
);

export const receiptsGet = createAsyncThunk(
  "installment/showReceipts",
  async (payload) => {
    return apiInstance.get(`installment/showReceipts?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`);
  }
);
export const remindingFeeGet = createAsyncThunk(
  "installment/showRemindingFee",
  async (payload) => {
    return apiInstance.get(`installment/showRemindingFee?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`);
  }
);

const installmentSlice = createSlice({
  name: "installmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // installmentGet
    builder.addCase(installmentGet.fulfilled, (state, action) => {
      state.installment = action.payload.installment;
      state.installmentCount = action.payload.installmentCount;
    });

    // receiptsGet
    builder.addCase(receiptsGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(receiptsGet.fulfilled, (state, action) => {
      state.receipts = action.payload.receipts;
      state.receiptsCount = action.payload.receiptsTotal;
      state.isSkeleton = false;
    });
    builder.addCase(receiptsGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // remindingFeeGet
    builder.addCase(remindingFeeGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(remindingFeeGet.fulfilled, (state, action) => {
      state.remindingFee = action.payload.installment;
      state.remindingFeeCount = action.payload.installmentTotal;
      state.isSkeleton = false;
    });
    builder.addCase(remindingFeeGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default installmentSlice.reducer;
