import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  studentHoliday: [],
  studentHolidayTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const studentHolidayGet = createAsyncThunk(
  "studentHoliday/showStudentHoliday",
  async (payload) => {
    return apiInstance.get(`studentHoliday/showStudentHoliday?year=${payload}`);
  }
);
export const studentHolidayAdd = createAsyncThunk(
  "studentHoliday/createStudentHoliday",
  async (payload) => {
    return apiInstance.post("studentHoliday/createStudentHoliday", payload);
  }
);
export const studentHolidayUpdate = createAsyncThunk(
  "studentHoliday/updateStudentHoliday",
  async (payload) => {
    return apiInstance.patch(`studentHoliday/updateStudentHoliday`, payload);
  }
);
export const studentHolidayDelete = createAsyncThunk(
  "studentHoliday/deleteStudentHoliday",
  async (payload) => {
    return apiInstance.delete(
      `studentHoliday/deleteStudentHoliday?studentHolidayId=${payload}`
    );
  }
);

const studentHolidaySlice = createSlice({
  name: "studentHolidaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // studentHolidayGet
    builder.addCase(studentHolidayGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(studentHolidayGet.fulfilled, (state, action) => {
      state.studentHoliday = action.payload.studentHoliday;
      state.studentHolidayTotal = action.payload.holidayTotal;
      state.isSkeleton = false;
    });
    builder.addCase(studentHolidayGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // holidayCreate
    builder.addCase(studentHolidayAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentHolidayAdd.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.studentHoliday.unshift(action.payload.studentHoliday);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentHolidayAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentHolidayUpdate
    builder.addCase(studentHolidayUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentHolidayUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const holidayIdx = state.studentHoliday.findIndex(
          (holiday) => holiday._id === action.payload.studentHoliday._id
        );
        if (holidayIdx !== -1) {
          state.studentHoliday[holidayIdx] = {
            ...state.studentHoliday[holidayIdx],
            ...action.payload.studentHoliday,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentHolidayUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentHolidayDelete
    builder.addCase(studentHolidayDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentHolidayDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.studentHoliday = state.studentHoliday.filter(
          (holiday) => holiday._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentHolidayDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default studentHolidaySlice.reducer;
