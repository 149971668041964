import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  inquiry: [],
  inquiryTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const inquiryGet = createAsyncThunk("inquiry/show", async (payload) => {
  return apiInstance.get(
    `inquiry/show?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}`
  );
});
export const inquiryAdd = createAsyncThunk(
  "inquiry/create",
  async (payload) => {
    return apiInstance.post("inquiry/create", payload);
  }
);
export const inquiryUpdate = createAsyncThunk(
  "inquiry/update",
  async (payload) => {
    return apiInstance.patch(
      `inquiry/update?inquiryId=${payload.inquiryId}`,
      payload.addInquiry
    );
  }
);
export const inquiryDelete = createAsyncThunk(
  "inquiry/delete",
  async (inquiryId) => {
    return apiInstance.delete(`inquiry/delete?inquiryId=${inquiryId}`);
  }
);

export const inquiryStatusUpdate = createAsyncThunk(
  "inquiry/changeStatus",
  async (payload) => {
    return apiInstance.put(
      `inquiry/changeStatus?inquiryId=${payload.inquiryId}&status=${payload.status}`
    );
  }
);

const inquirySlice = createSlice({
  name: "inquirySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // inquiryGet
    builder.addCase(inquiryGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(inquiryGet.fulfilled, (state, action) => {
      state.inquiry = action.payload.inquiry;
      state.inquiryTotal = action.payload.inquiryTotal;
      state.isSkeleton = false;
    });
    builder.addCase(inquiryGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // inquiryCreate
    builder.addCase(inquiryAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inquiryAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.inquiry.unshift(action.payload.inquiry);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(inquiryAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // inquiryUpdate
    builder.addCase(inquiryUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inquiryUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const inquiryIdx = state.inquiry.findIndex(
          (inquiry) => inquiry._id === action.payload.inquiry._id
        );
        if (inquiryIdx !== -1) {
          state.inquiry[inquiryIdx] = {
            ...state.inquiry[inquiryIdx],
            ...action.payload.inquiry,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(inquiryUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // inquiryDelete
    builder.addCase(inquiryDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inquiryDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.inquiry = state.inquiry.filter(
          (inquiry) => inquiry._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(inquiryDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    // inquiryStatusUpdate
    builder.addCase(inquiryStatusUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inquiryStatusUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const inquiryIdx = state.inquiry.findIndex(
          (inquiry) => inquiry._id === action.payload.inquiry._id
        );
        if (inquiryIdx !== -1) {
          state.inquiry[inquiryIdx] = {
            ...state.inquiry[inquiryIdx],
            ...action.payload.inquiry,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(inquiryStatusUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default inquirySlice.reducer;
