import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { closeDialog, openDialog } from "../../../../redux/slice/dialogSlice";
import { staffUpdate } from "../../../../redux/slice/staffSlice";
import Input, { Select } from "../../../extra/Input";
import { editData, objectToFormData, submitData } from "../../../util/fuction";
import { showPassword } from "../../../../redux/slice/staffSlice";
import { staffAttendanceGet } from "../../../../redux/slice/staffAttendanceSlice";
import StaffAttendanceDetails from "./StaffAttendanceDetails";
import StaffReport from "../Salary/StaffReport";
const StaffAttendance = () => {
  const { admin } = useSelector((state) => state.auth);
  const { dialogueType, dialogue } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");

  const { attendance, attendanceYear } = useSelector(
    (state) => state.staffAttendance
  );

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // Server Get
  useEffect(() => {
    if (admin?._id) {
      console.log("admin", admin);
      dispatch(
        staffAttendanceGet({ staffId: admin._id, year: years, command: false })
      );
    }
  }, [search, admin, years]);

  useEffect(() => {
    if (admin?._id) {
      dispatch(
        staffAttendanceGet({ staffId: admin._id, year: years, command: true })
      );
    }
  }, []);
  useEffect(() => {
    setData(attendance);
  }, [attendance]);

  const attendanceTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Month",
      body: "month",
      bText: "-",
      bBody: "monthId",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Year",
      body: "year",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Hours",
      body: "totalHours",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Break Hours",
      body: "totalBreakHours",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Final Total",
      body: "finalTotal",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-blue-light text-blue  border-blue-1  m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staffAttendanceDetails",
                  data: { ...row },
                })
              )
            }
            bIcon={`ri-calendar-check-line`}
            text={`Attendance`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Verify Salary",
      Cell: ({ row }) => (
        <span className="midBox">
          {row.payingRequest ? (
            <i class="ri-check-double-line fs-20 text-success"></i>
          ) : (
            <i class="ri-loader-2-line fs-20 text-blue"></i>
          )}
          <ActionButton
            className="bg-success-light text-success  border-success-1  m15-left"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staffInfo",
                  data: { ...row },
                })
              )
            }
            bIcon={`ri-file-chart-line`}
            text={`Report`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Pay",
      Cell: ({ row }) => (
        <span className="midBox">
          {row.isPay ? (
            <i class="ri-checkbox-circle-line fs-20 text-success"></i>
          ) : (
            <i class="ri-close-circle-line fs-20 text-danger"></i>
          )}
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Attendance"} icon={`ri-timer-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={attendance}
              setData={setData}
              column={attendanceTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={attendanceYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
        </div>
      </div>

      <Table data={data} mapData={attendanceTable} />

      {dialogue && dialogueType === "staffAttendanceDetails" && (
        <StaffAttendanceDetails />
      )}
      {dialogue && dialogueType === "staffInfo" && <StaffReport />}
    </>
  );
};

export default StaffAttendance;
