import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../util/Alert";
import BatchAdd from "./BatchAdd";
import {
  batchAction,
  batchDelete,
  batchGet,
} from "../../../../redux/slice/batchSlice";
import ToggleSwitch from "../../../extra/ToggleSwitch";
const Batch = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { batch, batchTotal } = useSelector((state) => state.batch);

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // const [field, setField] = useState("date");
  const [type, setType] = useState(0);
  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    // setField(value);
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(batchGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(batchGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(batch);
  }, [batch]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(batchDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleBatchAction = (batchId) => {
    const data = warning(
      `${batchId.batchName}`,
      `Are You Sure ${batchId.isActive ? "Deactive" : "Active"}?`,
      `${batchId.isActive ? "Deactive" : "Active"}`,
      `${
        batchId.isActive ? "ri-close-circle-line" : "ri-checkbox-circle-line"
      }`,
      `${batchId.isActive ? "second" : "success"}`
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(batchAction(batchId._id));
        }
      })
      .catch((err) => console.log(err));
  };

  const batchTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    { Header: "Batch Name", body: "batchName", sorting: { type: "client" } },
    { Header: "Batch Time", body: "batchTime", sorting: { type: "client" } },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleBatchAction(row)}
            value={row.isActive}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-darkGray-1 m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "batch",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-danger-1 m5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Batch"} icon={`ri-group-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={batchTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-darkGray-2 m20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "batch" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={batchTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={batchTotal}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {dialogue && dialogueType === "batch" && <BatchAdd />}
    </>
  );
};

export default Batch;
