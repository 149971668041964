import { useDispatch } from "react-redux";
import Button from "./Button";
import { closeDialog } from "../../redux/slice/dialogSlice";
const DialogBox = (props) => {
  const {
    handleSubmit,
    id,
    title,
    head,
    foot,
    columns,
    mainDialogClass,
    closeClick,
  } = props;
  const dispatch = useDispatch();

  return (
    <div>
      <div className="dialog">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className={columns ? columns : "col-xl-5 col-md-8 col-11"}>
              <div className="masterDiaogBox position-relative">
                {head && (
                  <div
                    className="closeButton  position-absolute"
                    style={{ top: 10, right: 10, zIndex: "2" }}
                    onClick={() => {
                      closeClick ? closeClick() : dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-circle-line"></i>
                  </div>
                )}
                <div className={`mainDiaogBox ${mainDialogClass}`}>
                  {handleSubmit ? (
                    <form onSubmit={handleSubmit} id={id}>
                      {!head && (
                        <DialogHeader
                          title={title}
                          closeClicks={() => {
                            closeClick ? closeClick() : dispatch(closeDialog());
                          }}
                        />
                      )}

                      {props.children}

                      {!foot && (
                        <DialogFooter
                          closeClicks={() => {
                            closeClick ? closeClick() : dispatch(closeDialog());
                          }}
                        />
                      )}
                    </form>
                  ) : (
                    <div id={id}>
                      {!head && (
                        <DialogHeader
                          title={title}
                          closeClicks={() => {
                            closeClick ? closeClick() : dispatch(closeDialog());
                          }}
                        />
                      )}

                      {props.children}

                      {!foot && (
                        <DialogFooter
                          closeClicks={() => {
                            closeClick ? closeClick() : dispatch(closeDialog());
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;

export const DialogHeader = ({ title, closeClicks }) => {
  const dispatch = useDispatch();
  return (
    <div className="row justify-content-between align-items-start formHead">
      <div className="col-8 p0">
        <h3 className="headTitle">{title}</h3>
      </div>
      <div className="col-4 p0">
        <div className="closeButton " onClick={closeClicks}>
          <i class="ri-close-circle-line"></i>
        </div>
      </div>
    </div>
  );
};
export const DialogFooter = ({ closeClicks }) => {
  const dispatch = useDispatch();
  return (
    <div className="row m5-top p0-x formFooter">
      <div className="col-12 text-end m0">
        <Button
          className={`bg-gray2 text-darkGray border-darkGray-2`}
          text={`Cancel`}
          type={`button`}
          onClick={closeClicks}
        />
        <Button
          type={`submit`}
          className={`bg-second text-light border-second-light-2 m10-left`}
          text={`Submit`}
        />
      </div>
    </div>
  );
};
