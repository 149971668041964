import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { baseURL } from "../../../util/config";
import { useEffect, useRef, useState } from "react";
import Button from "../../../extra/Button";
import moment from "moment";
import { DashBox } from "../../dashboard/Dashboard";
import { studentAttendanceGetInfo } from "../../../../redux/slice/studentAttendanceSlice";
import { PrintReceipt } from "../StudentInstallment/InstallmentDetails";
import DialogBox from "../../../extra/DialogBox";
const StudentAttendanceDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { attendanceInfo, attendanceDash } = useSelector(
    (state) => state.studentAttendance
  );

  const {
    totalMonthCount,
    totalPresent,
    totalAbsent,
    totalLate,
    totalHoliday,
  } = attendanceDash;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(studentAttendanceGetInfo(dialogueData._id));
  }, []);

  return (
    <DialogBox columns={`col-11`} foot={true} head={true}>
      <div className="row align-items-center formBody">
        <div className="col-lg-4 col-md-6 col-12 text-start">
          <div className="mainLogoPart">
            <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
              <img src={Logo} alt="logo" width={`100%`} />
            </div>
          </div>
          <div className="studentImage m-auto m10-bottom hw-100 border-second-3 rounded-3 overflow-hidden">
            <img
              src={baseURL + dialogueData?.studentPhoto}
              alt="student image"
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className="FacultyName text-center text-second">
            <h5>{dialogueData?.studentName}</h5>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-12">
          <div className="row">
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Total Days`}
                count={totalPresent + totalLate + totalAbsent + totalHoliday}
                className={`p10`}
                classNameBody={`fs-18 text-orange`}
                classNameHead={`fs-12`}
              />
            </div>
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Working Days`}
                count={totalPresent + totalLate + totalAbsent}
                className={`p10`}
                classNameBody={`fs-18 text-second`}
                classNameHead={`fs-12`}
              />
            </div>
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Total Present (R/L)`}
                count={totalPresent + totalLate}
                sideText={`(${totalPresent}/${totalLate})`}
                className={`p10`}
                classNameBody={`fs-18 text-success`}
                classNameHead={`fs-12`}
              />
            </div>
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Total Absent`}
                count={totalAbsent}
                className={`p10`}
                classNameBody={`fs-18 text-danger`}
                classNameHead={`fs-12`}
              />
            </div>
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Total Holiday`}
                count={totalHoliday}
                className={`p10`}
                classNameBody={`fs-18 text-blue`}
                classNameHead={`fs-12`}
              />
            </div>
            <div className="col-lg-3 col-xsm-4 col-6">
              <DashBox
                title={`Total Month`}
                count={totalMonthCount}
                className={`p10`}
                classNameBody={`fs-18`}
                classNameHead={`fs-12`}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="attendanceShowTable">
            <table width={`100%`}>
              <tr
                className="position-sticky bg-light"
                style={{ top: 0, zIndex: 1 }}
              >
                <th className="text-center yearHead thHead">
                  <div className="tblDiv">Year</div>
                </th>
                <th className="text-center monthHead thHead">
                  <div className="tblDiv">Month | Date</div>
                </th>
                {[...Array(31)].map((res, i) => (
                  <th className="text-center showAtten dataHead thHead">
                    <div className="tblDiv">{i + 1}</div>
                  </th>
                ))}
                <th className="text-center showAtten pHead thHead">
                  <div className="tblDiv">P</div>
                </th>
                <th className="text-center showAtten lHead thHead">
                  <div className="tblDiv">L</div>
                </th>
                <th className="text-center showAtten aHead thHead">
                  <div className="tblDiv">A</div>
                </th>
                <th className="text-center showAtten hHead thHead">
                  <div className="tblDiv">H</div>
                </th>
                <th className="text-center totalHead thHead">
                  <div className="tblDiv">Total</div>
                </th>
              </tr>
              {attendanceInfo?.length > 0 ? (
                <>
                  {attendanceInfo.map((yearsResponse, i) => {
                    return (
                      <>
                        {yearsResponse?.attendanceData?.map(
                          (monthResponse, i) => {
                            return (
                              <tr
                                className={`${
                                  i == yearsResponse.monthCount - 1 && "endYear"
                                }`}
                              >
                                {i === 0 && (
                                  <td
                                    rowSpan={yearsResponse.monthCount}
                                    height={`100%`}
                                    className="text-center  yearBody tdBody"
                                  >
                                    <div
                                      className={`tblDiv h-100 ${
                                        yearsResponse?.attendanceData?.length <
                                          3 && "fs-14 "
                                      }`}
                                      style={{
                                        transform:
                                          yearsResponse?.attendanceData
                                            ?.length < 3
                                            ? "rotate(0deg)"
                                            : "rotate(270deg)",
                                      }}
                                    >
                                      {yearsResponse?._id}
                                    </div>
                                  </td>
                                )}
                                <td className="text-center monthBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse?.month}
                                  </div>
                                </td>
                                {monthResponse?.attendance.map((res) => (
                                  <td
                                    className={`text-center dataBody tdBody ${
                                      (res.status == "A" &&
                                        res.isLeave &&
                                        "bg-danger-light text-danger outline-danger") ||
                                      (res.status == "A" &&
                                        !res.isLeave &&
                                        "bg-second-light text-second outline-second") ||
                                      (res?.status == "H" &&
                                        "bg-blue-light text-blue  outline-blue") ||
                                      (res?.status == "P" &&
                                        "text-success bg-success-light outline-success") ||
                                      (res?.status == "L" &&
                                        "text-orange bg-orange-light outline-orange")
                                    }`}
                                  >
                                    <div className="tblDiv">{res?.status}</div>
                                  </td>
                                ))}
                                {Array(
                                  Math.max(
                                    0,
                                    31 - (monthResponse?.totalDays || 0)
                                  )
                                )
                                  .fill()
                                  .map(() => (
                                    <td className="text-center dataBody tdBody bg-lightGray">
                                      <div className="tblDiv">
                                        <i className="ri-close-circle-line"></i>
                                      </div>
                                    </td>
                                  ))}
                                <td className="text-center pBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse.PCount}
                                  </div>
                                </td>
                                <td className="text-center lBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse.LCount}
                                  </div>
                                </td>
                                <td className="text-center aBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse.ACount}
                                  </div>
                                </td>
                                <td className="text-center hBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse.HCount}
                                  </div>
                                </td>
                                <td className="text-center totalBody tdBody">
                                  <div className="tblDiv">
                                    {monthResponse.PCount +
                                      monthResponse.LCount +
                                      monthResponse.ACount +
                                      monthResponse.HCount}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="25" className="text-center">
                    No Data Found !
                  </td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default StudentAttendanceDetails;
