import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { closeDialog, openDialog } from "../../../../redux/slice/dialogSlice";
import {
  staffDelete,
  staffGet,
  staffSwitch,
  staffUpdate,
} from "../../../../redux/slice/staffSlice";
import MaleImg from "../../../../assets/images/male.png";
import FemaleImg from "../../../../assets/images/female.png";
import { warning } from "../../../util/Alert";
import StaffAdd from "./StaffAdd";
import { baseURL } from "../../../util/config";
import StaffDetails from "./StaffDetails";
import Input from "../../../extra/Input";
import { editData, submitData } from "../../../util/fuction";
import { showPassword } from "../../../../redux/slice/staffSlice";
import StaffLeaveAdd from "../StaffLeave/StaffLeaveAdd";
import StaffAttendanceAll from "../StaffAttendance/StaffAttendanceAll";
import StaffLeaveAll from "../StaffLeave/StaffLeaveAll";
import StaffSalaryAll from "../Salary/StaffSalaryAll";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import DialogBox from "../../../extra/DialogBox";
const Staff = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [showLeave, setShowLeave] = useState({
    open: false,
    leaveData: {},
  });
  const [showAttendance, setShowAttendance] = useState({
    open: false,
    attendanceData: {},
  });
  const [showSalary, setShowSalary] = useState({
    open: false,
    salaryData: {},
  });

  const reGetData = localStorage.getItem("leaveShow");
  useEffect(() => {
    if (reGetData) {
      setShowLeave(JSON.parse(reGetData));
    }
  }, []);

  const reAtttendanceGetData = localStorage.getItem("attendanceShow");
  useEffect(() => {
    if (reAtttendanceGetData) {
      setShowAttendance(JSON.parse(reAtttendanceGetData));
    }
  }, []);

  const reSalaryGetData = localStorage.getItem("salaryShow");
  useEffect(() => {
    if (reSalaryGetData) {
      setShowSalary(JSON.parse(reSalaryGetData));
    }
  }, []);

  const { staff, staffTotal } = useSelector((state) => state.staff);

  // Pagination BOTH
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
  };
  // Server Get
  useEffect(() => {
    dispatch(staffGet({ ...payload, command: false }));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    dispatch(staffGet({ ...payload, command: true }));
  }, []);
  useEffect(() => {
    setData(staff);
  }, [staff]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLeaveOpenDialoguePage = (row) => {
    setShowLeave({ open: true, leaveData: row });
    const setLeaveData = { open: true, leaveData: row };
    localStorage.setItem("leaveShow", JSON.stringify(setLeaveData));
  };

  const handleAttendanceOpenDialoguePage = (row) => {
    setShowAttendance({ open: true, attendanceData: row });
    const setAttendanceData = { open: true, attendanceData: row };
    localStorage.setItem("attendanceShow", JSON.stringify(setAttendanceData));
  };

  const handleSalaryOpenDialoguePage = (row) => {
    setShowSalary({ open: true, salaryData: row });
    const setAttendanceData = { open: true, salaryData: row };
    localStorage.setItem("salaryShow", JSON.stringify(setAttendanceData));
  };

  const handleStaffAction = (staffId, status, value) => {
    let data;
    if (status == 1) {
      data = warning(
        `${value ? "Unblock" : "Block"}`,
        `Are You Sure ${value ? "Unblock" : "Block"}?`,
        `${value ? "Unblock" : "Block"}`,
        `${value ? "ri-checkbox-circle-line" : "ri-close-circle-line"}`,
        `${value ? "success" : "second"}`
      );
    } else if (status == 2) {
      data = warning(
        `${value ? "Remove HR" : "Make HR"}`,
        `Are You Sure ${value ? "Remove HR" : "Make HR"}?`,
        `${value ? "Remove" : "Make"}`,
        `${value ? "ri-close-circle-line" : "ri-checkbox-circle-line"}`,
        `${value ? "second" : "success"}`
      );
    } else if (status == 3) {
      data = warning(
        `${value ? "Remove Receptionist" : "Make Receptionist"}`,
        `Are You Sure ${value ? "Remove Receptionist" : "Make Receptionist"}?`,
        `${value ? "Remove" : "Make"}`,
        `${value ? "ri-close-circle-line" : "ri-checkbox-circle-line"}`,
        `${value ? "second" : "success"}`
      );
    } else if (status == 4) {
      data = warning(
        `${value ? "Access Decline" : "Access Accept"}`,
        `Are You Sure ${value ? "Access Decline" : "Access Accept"}?`,
        `${value ? "Decline" : "Accept"}`,
        `${value ? "ri-close-circle-line" : "ri-checkbox-circle-line"}`,
        `${value ? "second" : "success"}`
      );
    }

    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(staffSwitch({ staffId, status }));
        }
      })
      .catch((err) => console.log(err));
  };

  const staffTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Staff Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="d-flex align-items-center">
          <span>
            <div className="height-30 rounded-5 width-30 bg-second m20-right overflow-hidden">
              <img
                src={
                  row?.image
                    ? baseURL + row?.image
                    : row?.gender == "male"
                    ? MaleImg
                    : FemaleImg
                }
                alt=""
                height={`100%`}
              />
            </div>
          </span>
          <span className="m15-right">{row?.staffName}</span>
        </span>
      ),
      sorting: { type: "client" },
      class: "text-center",
    },
    {
      Header: "Staff Code",
      body: "staffCode",
      bText: "#",
      sorting: { type: "client" },
    },
    {
      Header: "Punch",
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
      Cell: ({ row }) => (
        <span>
          {row.isBlock ? (
            <ActionButton
              className="bg-dark text-light border-dark-1 m5-right"
              text={`Block`}
            />
          ) : (
            <>
              {row.isPunch && row.isBreak ? (
                <ActionButton
                  className="bg-warning-light text-warning border-warning-1 m5-right"
                  text={`Break`}
                />
              ) : row.isPunch && !row.isBreak ? (
                <ActionButton
                  className="bg-success-light text-success border-success-1 m5-right"
                  text={`Punch IN`}
                />
              ) : (
                !row.isPunch &&
                !row.isBreak && (
                  <ActionButton
                    className="bg-danger-light text-danger border-danger-1 m5-right"
                    text={`Punch Out`}
                  />
                )
              )}
            </>
          )}
        </span>
      ),
    },
    { Header: "Position", body: "position", sorting: { type: "client" } },
    { Header: "Email", body: "email", sorting: { type: "client" } },
    {
      Header: "Gender",
      body: "gender",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Mobile No",
      body: "phoneNumber",
      sorting: { type: "client" },
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Joining Date",
      body: "joiningDate",
      sorting: { type: "client" },
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Block",
      body: "isBlock",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleStaffAction(row._id, 1, row.isBlock)}
            value={row.isBlock}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Punch",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-orange-light text-orange border-orange-1 m5-right"
            onClick={() => handleAttendanceOpenDialoguePage(row)}
            icon={`ri-user-follow-line`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Salary",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-success-light text-success border-success-1 m5-right"
            onClick={() => handleSalaryOpenDialoguePage(row)}
            icon={`ri-wallet-3-line`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Leave",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className={`bg-prime-light text-prime border-prime-1 m5-right`}
            icon={`ri-file-add-line`}
            onClick={() => {
              dispatch(
                openDialog({
                  type: "leaveAdd",
                  text: { staffCode: row?.staffCode },
                })
              );
            }}
          />
          <ActionButton
            className="bg-blue-light text-blue border-blue-1 m5-right"
            onClick={() => handleLeaveOpenDialoguePage(row)}
            icon={`ri-user-shared-2-line`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-darkGray-1  m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staff",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-second-light text-second border-second-1  m5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
          <ActionButton
            className="bg-warning-light text-warning border-warning-1 m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staffDetails",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-information-line`}
          />
          <ActionButton
            className="bg-success-light text-success border-success-1 m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staffPassword",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-lock-line`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "HR",
      body: "isHr",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleStaffAction(row._id, 2, row.isHr)}
            value={row.isHr}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Receptionist",
      body: "isReceptionist",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleStaffAction(row._id, 3, row.isReceptionist)}
            value={row.isReceptionist}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Self Access",
      body: "selfAccess",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleStaffAction(row._id, 4, row?.selfAccess)}
            value={row?.selfAccess}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <div>
      <div className="row justify-content-between align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title name={"Staff"} icon={`ri-user-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={staffTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-darkGray-2 m20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "staff" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={staffTable}
        serverPerPage={rowsPerPage}
        Page={page}
      />

      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={staffTotal}
      />

      {dialogue && dialogueType === "staff" && <StaffAdd />}
      {dialogue && dialogueType === "staffDetails" && <StaffDetails />}
      {dialogue && dialogueType === "staffPassword" && <ShowPassword />}
      {dialogue && dialogueType === "leaveAdd" && <StaffLeaveAdd />}
      {showLeave.open && (
        <StaffLeaveAll showLeave={showLeave} setShowLeave={setShowLeave} />
      )}
      {showAttendance.open && (
        <StaffAttendanceAll
          showAttendance={showAttendance}
          setShowAttendance={setShowAttendance}
        />
      )}
      {showSalary.open && (
        <StaffSalaryAll showSalary={showSalary} setShowSalary={setShowSalary} />
      )}
    </div>
  );
};

export default Staff;

export const ShowPassword = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { passShow } = useSelector((state) => state.staff);

  useEffect(() => {
    if (dialogueData) {
      dispatch(showPassword(dialogueData._id));
    }
  }, [dialogueData]);

  useEffect(() => {
    if (passShow) {
      editData({ password: passShow });
    }
  }, [passShow]);

  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    const updatePassword = submitData(e);
    if (updatePassword) {
      console.log("updatePassword", updatePassword);

      try {
        const payload = { formData: updatePassword, id: dialogueData._id };
        let response = await dispatch(staffUpdate(payload)).unwrap();
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`staffPasswordForm`}
      title={`${dialogueData.staffName}'s Password`}
      handleSubmit={handleSubmit}
      columns={`col-xl-4 col-md-7 col-10`}
    >
      <div className="formBody">
        <div className="startForm p50-bottom">
          <Input
            type={`password`}
            id={`password`}
            name={`password`}
            label={`Password`}
            errorMessage={`Enter Password`}
          />
        </div>
      </div>
    </DialogBox>
  );
};
