import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  studentHolidayAdd,
  studentHolidayUpdate,
} from "../../../../redux/slice/studentHolidaySlice";
import DialogBox from "../../../extra/DialogBox";

const StudentHolidayAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [isAttendanceChange, setIsAttendanceChange] = useState(true);
  const [isOldAttendanceChange, setIsOldAttendanceChange] = useState(true);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
      setIsAttendanceChange(dialogueData?.isAttendanceChange);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addHoliday = submitData(e);
    if (addHoliday) {
      let payload = { ...addHoliday, isAttendanceChange };
      try {
        let response;
        if (dialogueData) {
          const editHoliday = {
            ...payload,
            studentHolidayId: dialogueData._id,
            isOldAttendanceChange,
          };
          response = await dispatch(studentHolidayUpdate(editHoliday)).unwrap();
        } else {
          response = await dispatch(studentHolidayAdd(payload)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response?.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`holidaysForm`}
      title={`Holiday Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody p50-bottom">
        <div className="col-lg-6 col-12">
          <Input
            type={`date`}
            id={`holidayDate`}
            name={`holidayDate`}
            label={`Holiday Date`}
            errorMessage={`Enter Holiday Date`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`description`}
            name={`description`}
            label={`Description`}
            errorMessage={`Enter Description`}
          />
        </div>

        <div className="col-lg-6 col-12">
          <div className="inputData">
            <label>Apply New Date Holiday in Attendance ?</label>
          </div>
          <ActionButton
            type={`button`}
            className={`leaveType fs-14 ${
              isAttendanceChange
                ? "bg-success text-light"
                : "bg-success-light text-success"
            }  border-success-2 m10-right m5-top`}
            text={`Yes`}
            onClick={() => setIsAttendanceChange(true)}
          />
          <ActionButton
            type={`button`}
            className={`leaveType fs-14 ${
              !isAttendanceChange
                ? "bg-danger text-light"
                : "bg-danger-light text-danger"
            }  border-danger-2 m10-right m5-top`}
            text={`No`}
            onClick={() => setIsAttendanceChange(false)}
          />
        </div>
        {dialogueData && (
          <div className="col-lg-6 col-12">
            <div className="inputData">
              <label>Apply Old Date Holiday in Attendance ?</label>
            </div>
            <ActionButton
              type={`button`}
              className={`leaveType fs-14 ${
                isAttendanceChange
                  ? "bg-success text-light"
                  : "bg-success-light text-success"
              }  border-success-2 m10-right m5-top`}
              text={`Yes`}
              onClick={() => setIsOldAttendanceChange(true)}
            />
            <ActionButton
              type={`button`}
              className={`leaveType fs-14 ${
                !isAttendanceChange
                  ? "bg-danger text-light"
                  : "bg-danger-light text-danger"
              }  border-danger-2 m10-right m5-top`}
              text={`No`}
              onClick={() => setIsOldAttendanceChange(false)}
            />
          </div>
        )}
      </div>
    </DialogBox>
  );
};

export default StudentHolidayAdd;
