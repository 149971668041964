import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../extra/Input";
import moment from "moment";
import DialogBox from "../../../extra/DialogBox";

const InquiryDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  return (
    <DialogBox
      columns={`col-xl-5 col-md-8 col-11`}
      title={`Inquiry Info`}
      foot={true}
    >
      <div className="mainProfileBoard mainAdminProfile formBody">
        <div className="mainProfileBox">
          <div className="row">
            <div className=" col-12 m-auto">
              <div className="row">
                <div className=" col-12">
                  <div className="adminDetails p20">
                    <div className="adminProfileTitle">
                      Personal Information
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Name</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.name}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox ">
                      <div className="adminDetailTitle">Gender</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.gender}
                        defaultValue={`--`}
                        disabled={true}
                        className={`text-capitalize`}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Course</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.course}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Mobile No</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.mobileNo}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Inquiry Date</div>
                      <Input
                        type={`date`}
                        value={moment(dialogueData.createdAt).format(
                          "YYYY-MM-DD"
                        )}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default InquiryDetails;
