import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import Logo from "../../../../assets/images/logo.png";
import { baseURL } from "../../../util/config";
import {
  installmentCreateMultiple,
  installmentCreateSingle,
  installmentDelete,
  installmentGet,
  installmentPay,
  installmentUpdate,
} from "../../../../redux/slice/installmentSlice";
import { useEffect, useRef, useState } from "react";
import Input from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { submitData } from "../../../util/fuction";
import { warning } from "../../../util/Alert";
import moment from "moment";
import { setToast } from "../../../extra/toast";
import DialogBox from "../../../extra/DialogBox";
const InstallmentDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { installment, installmentCount } = useSelector(
    (state) => state.installment
  );

  const [editValue, setEditValue] = useState();
  const [editData, setEditData] = useState({
    installmentDate: "",
    installmentCount: "",
    installmentNumber: "",
    description: "",
    payDate: "",
    payMethod: "",
  });

  const [choiceAddition, setChoiceAddition] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(installmentGet(dialogueData._id));
  }, []);

  // Edit Installment
  const handleEditInstallment = async (installmentId) => {
    console.log("editData", editData);
    let response = await dispatch(
      installmentUpdate({ installmentId, details: editData })
    );

    response.payload.status
      ? dispatch(installmentGet(dialogueData._id))
      : setToast(response.payload.message);
    setEditValue({});
    setEditData({
      installmentDate: "",
      installmentCount: "",
      installmentNumber: "",
      description: "",
      payDate: "",
      payMethod: "",
    });
  };

  // Pay Installment
  const handlePayInstallment = async (installmentId, isPay) => {
    let response;
    const data = isPay
      ? warning(
          "Paid Installment",
          " ",
          "Paid",
          "ri-check-double-line",
          "success"
        )
      : warning("", "Unpaid Installment", "Unpaid");
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          response = await dispatch(installmentPay({ installmentId, isPay }));
          response.payload.status
            ? dispatch(installmentGet(dialogueData._id))
            : setToast(response.payload.message);
          setEditValue();
        }
      })
      .catch((err) => console.log(err));
  };
  // Delet Installment
  const handleDeleteInstallment = async (installmentId) => {
    const data = warning("", "Remove Installment");
    let response;
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          response = await dispatch(installmentDelete({ installmentId }));

          response.payload.status
            ? dispatch(installmentGet(dialogueData._id))
            : setToast(response.payload.message);
          setEditValue();
        }
      })
      .catch((err) => console.log(err));
  };

  const [prints, setPrints] = useState(0);

  const [datas, setDatas] = useState({});

  const handlePrintValues = (thisData) => {
    setDatas({
      name: dialogueData.studentName,
      date: thisData.payDate,
      course: dialogueData.course,
      installmentNumber: thisData.installmentNumber,
      payMethod: thisData.payMethod,
      amount: thisData.installmentCount,
      description: thisData.description,
      receiptNo: thisData.receiptNo,
    });

    console.log("datas in", datas);

    setPrints(1);
  };

  return (
    <div>
      <DialogBox columns={`col-sm-8 col-11`} head={true} foot={true}>
        <div className="row align-items-center formBody">
          <div className="col-lg-4 col-md-6 col-12 text-start">
            <div className="mainLogoPart">
              <div className="logoImg  width-sm-90 width-50 m-auto m10-bottom">
                <img src={Logo} alt="logo" width={`100%`} />
              </div>
            </div>
            <div className="studentImage m-auto m10-bottom hw-100 border-second-3 rounded-3 overflow-hidden">
              <img
                src={baseURL + dialogueData?.studentPhoto}
                alt="student image"
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="FacultyName text-center text-second">
              <h5>{dialogueData?.studentName}</h5>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 col-12">
            <div className="addButton m20-bottom">
              <ActionButton
                type={`button`}
                className="bg-blue-light text-blue  border-blue-1"
                icon={`ri-file-add-line`}
                onClick={() => setChoiceAddition(1)}
              />
              <ActionButton
                type={`button`}
                className="bg-blue-light text-blue  border-blue-1 m10-left"
                icon={`ri-checkbox-multiple-blank-line`}
                onClick={() => setChoiceAddition(2)}
              />
            </div>
            {choiceAddition == 0 ? (
              <ShowTotals installmentCount={installmentCount} />
            ) : choiceAddition == 1 ? (
              <AddSingleInstallment
                dialogueData={dialogueData}
                setChoiceAddition={setChoiceAddition}
              />
            ) : (
              choiceAddition == 2 && (
                <AddMultipleInstallment
                  dialogueData={dialogueData}
                  setChoiceAddition={setChoiceAddition}
                />
              )
            )}
          </div>
          <div className="col-12">
            <div className="installmentTable">
              <table width={`100%`}>
                <tr
                  className="position-sticky bg-light"
                  style={{ top: 0, zIndex: 1 }}
                >
                  <th className="text-center">No</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Installment</th>
                  <th className="text-center">Receipt No</th>
                  <th className="text-center">Pay</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Desription</th>
                  <th className="text-center">Pay Date / Method</th>
                  <th className="text-center">Delete</th>
                </tr>
                {installment.length > 0 ? (
                  <>
                    {installment.map((data, i) => {
                      return (
                        <tr className={data.isPay && "bg-light"}>
                          <td className="text-center" width={70}>
                            {i + 1}
                          </td>
                          <td className="text-center">
                            {editValue >= 0 ? (
                              <Input
                                type={`date`}
                                defaultValue={data?.installmentDate}
                                disabled={editValue !== i}
                                onChangeVal={(e) => {
                                  setEditData((old) => {
                                    return {
                                      ...old,
                                      installmentDate: e.target.value,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <Input
                                type={`date`}
                                value={data?.installmentDate}
                                disabled={editValue !== i}
                              />
                            )}
                          </td>
                          <td width={150} className="text-center">
                            {editValue >= 0 ? (
                              <Input
                                type={`number`}
                                defaultValue={data?.installmentCount}
                                disabled={editValue !== i}
                                onChangeVal={(e) => {
                                  setEditData((old) => {
                                    return {
                                      ...old,
                                      installmentCount: e.target.value,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <Input
                                type={`number`}
                                value={data?.installmentCount}
                                disabled={editValue !== i}
                              />
                            )}
                          </td>
                          <td width={50} className="text-center">
                            {editValue >= 0 ? (
                              <Input
                                type={`text`}
                                defaultValue={data?.receiptNo}
                                className={`m10-left width-100`}
                                disabled={true}
                                onChangeVal={(e) => {
                                  setEditData((old) => {
                                    return {
                                      ...old,
                                      receiptNo: e.target.value,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <Input
                                type={`text`}
                                value={data?.receiptNo}
                                disabled={true}
                                className={`m10-left width-100`}
                              />
                            )}
                          </td>
                          <td width={70} className="text-center">
                            {data?.isPay ? (
                              <ActionButton
                                type={`button`}
                                className="bg-blue-light text-blue  border-blue-1"
                                icon={`ri-printer-line`}
                                onClick={() => handlePrintValues(data)}
                              />
                            ) : (
                              <Button
                                type={`button`}
                                className={`bg-success-light text-success border-success-1`}
                                icon={`ri-paypal-line`}
                                onClick={() =>
                                  handlePayInstallment(data?._id, true)
                                }
                              />
                            )}
                          </td>
                          <td width={50} className="text-center">
                            {editValue == i ? (
                              <ActionButton
                                type={`button`}
                                className="bg-success-light text-success  border-success-1  m5-right"
                                icon={`ri-check-double-fill`}
                                onClick={() => handleEditInstallment(data?._id)}
                              />
                            ) : (
                              <ActionButton
                                type={`button`}
                                className="bg-gray2 text-darkGray  border-darkGray-1  m5-right"
                                icon={`ri-pencil-fill`}
                                onClick={() => setEditValue(i)}
                              />
                            )}
                          </td>
                          <td width={220} className="text-center">
                            {editValue >= 0 ? (
                              <Input
                                type={`text`}
                                defaultValue={data?.description}
                                className={`m10-left`}
                                disabled={editValue !== i}
                                onChangeVal={(e) => {
                                  setEditData((old) => {
                                    return {
                                      ...old,
                                      description: e.target.value,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <Input
                                type={`text`}
                                value={data?.description}
                                disabled={editValue !== i}
                                className={`m10-left`}
                              />
                            )}
                          </td>
                          <td className="text-center d-flex justify-content-center">
                            {editValue >= 0 ? (
                              <>
                                <Input
                                  type="date"
                                  defaultValue={data?.payDate}
                                  disabled={editValue !== i}
                                  className="width-150"
                                  onChangeVal={(e) => {
                                    setEditData((old) => ({
                                      ...old,
                                      payDate: e.target.value,
                                    }));
                                  }}
                                />
                                <Input
                                  type={`text`}
                                  defaultValue={data?.payMethod}
                                  className={`m10-left width-100`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        payMethod: e.target.value,
                                      };
                                    });
                                  }}
                                />
                                <Input
                                  type={`number`}
                                  defaultValue={data?.installmentNumber}
                                  className={`m10-left width-50 text-center`}
                                  disabled={editValue !== i}
                                  onChangeVal={(e) => {
                                    setEditData((old) => {
                                      return {
                                        ...old,
                                        installmentNumber: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  type={`date`}
                                  value={data?.payDate}
                                  disabled={editValue !== i}
                                  className="width-150"
                                />
                                <Input
                                  type={`text`}
                                  value={data?.payMethod}
                                  disabled={editValue !== i}
                                  className={`m10-left width-100`}
                                />
                                <Input
                                  type={`number`}
                                  value={data?.installmentNumber}
                                  disabled={editValue !== i}
                                  className={`text-center m10-left width-50`}
                                />
                              </>
                            )}
                          </td>
                          <td width={50} className="text-center">
                            {data?.isPay ? (
                              <ActionButton
                                type={`button`}
                                className="bg-danger-light text-danger  border-danger-1  m5-right"
                                icon={`ri-close-line`}
                                onClick={() =>
                                  handlePayInstallment(data?._id, false)
                                }
                              />
                            ) : (
                              <ActionButton
                                type={`button`}
                                className="bg-second-light text-second border-second-1  m5-right"
                                icon={`ri-delete-bin-line`}
                                onClick={() =>
                                  handleDeleteInstallment(data?._id)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan="25" className="text-center">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </DialogBox>
      {prints == 1 && <PrintReceipt setPrints={setPrints} datas={datas} />}
    </div>
  );
};

export default InstallmentDetails;

export const PrintReceipt = ({ setPrints, datas }) => {
  console.log("datas", datas);
  const printableContentRef = useRef(null);

  const printDiv = (findClick) => {
    const styleElements = document.querySelectorAll("style");
    const linkElements = document.querySelectorAll("link");
    const content = printableContentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");

    let newStyles = "";
    let newLinks = "";
    for (const style of styleElements) {
      newStyles += style.textContent;
    }
    for (const link of linkElements) {
      newLinks += link.outerHTML;
    }

    newWindow.document.write(`
    <html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
  ${newLinks}
      <style>
        ${newStyles}
      </style\>
    </head\>
    <script src="https://kit.fontawesome.com/e9f488f24b.js" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossorigin="anonymous"></script>
    <body><div class="${
      findClick == 2 && "doublePrint"
    }">${content}</div></body>
    </html>
`);
    newWindow.document.close();
    newWindow.print();
    newWindow.onafterprint = () => newWindow.close();
    setPrints(0);
  };

  return (
    <DialogBox
      columns={`col-xxl-5 col-xl-6 col-lg-8 col-md-9 col-11`}
      foot={true}
      head={true}
      closeClick={() => setPrints(0)}
      mainDialogClass={`bg-transparent`}
    >
      <div class="receipt-container" ref={printableContentRef}>
        {[0, 1].map((_, i) => {
          return (
            <div className={`receipt-main ${i == 1 && "secondPrintMain"}`}>
              <div className={`startReceipt ${i == 1 && "secondPrint"}`}>
                <div class="receipt-header">
                  <h4 align={`center`}>Fees Receipt</h4>
                  <div className="headerContet p10-y border-bottom-dark-1">
                    <div className="logoContent">
                      <div className="logoImg  width-sm-240 width-150">
                        <img src={Logo} alt="logo" width={`100%`} />
                      </div>
                    </div>
                    <div className="iconContent">
                      <div className="iconsDetails">
                        <div className="icons">
                          <div className="iconsImg midBox hw-22">
                            <i class="ri-phone-fill"></i>
                          </div>
                          <div className="iconsText fw-600"> : 88666 25957</div>
                        </div>
                        <div className="icons">
                          <div className="iconsImg midBox hw-22">
                            <i class="ri-mail-fill"></i>
                          </div>
                          <div className="iconsText fw-600">
                            {" "}
                            : multikodit@gmail.com
                          </div>
                        </div>
                        <div className="icons">
                          <div className="iconsImg midBox hw-22">
                            <i class="ri-global-line"></i>
                          </div>
                          <div className="iconsText fw-600">
                            {" "}
                            : multikodit.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="receipt-body">
                  <div className="startBody">
                    <div className="row">
                      <div className="col-8">
                        <div className="tages">
                          <span>REC.NO : </span>
                          <span>{datas.receiptNo}</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="tages">
                          <span>DATE : </span>
                          <span>{moment(datas.date).format("DD-MM-YYYY")}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="tages">
                          <span>STUDENT NAME : </span>
                          <span>{datas.name}</span>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="tages">
                          <span>COURSE : </span>
                          <span>{datas.course}</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="tages">
                          <span>INSTALLMENT NO : </span>
                          <span>
                            {datas.installmentNumber.toString().length === 1
                              ? "0" + datas.installmentNumber
                              : datas.installmentNumber}
                          </span>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="tages">
                          <span>PAYMENT BY : </span>
                          <span>{datas.payMethod}</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="tages">
                          <span>AMOUNT : </span>
                          <span>{datas.amount}/-</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="tages">
                          <span>DESCRIPTION : </span>
                          <span>{datas.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="receipt-footer">
                  <div className="startFooter">
                    <div className="row">
                      <div className="col-8">
                        <div className="tages">
                          <span>STUDENT SIGN</span>
                          <span className="signLine"></span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="tages">
                          <span>RECEIVER SIGN</span>
                          <span className="signLine"></span>
                        </div>
                      </div>
                      <div className="col-12 m0 fs-12 fw-bold">
                        <span>
                          NOTE: FEES WILL NOT REFUNDABLE IN ANY CASES.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="actionButtonForPrint m20-top midBox formFooter bg-transparent">
        <Button
          className={`bg-second text-light border-second-2`}
          bIcon={`ri-printer-line`}
          text={`Print`}
          type={`button`}
          onClick={() => printDiv(1)}
        />
        <Button
          className={`bg-second text-light border-second-2 m5-left`}
          bIcon={`ri-printer-line`}
          text={`Print(2)`}
          type={`button`}
          onClick={() => printDiv(2)}
        />
        <Button
          className={`bg-darkGray text-light border-darkGray-2 m5-left`}
          bIcon={`ri-close-line`}
          text={`Cancel`}
          type={`button`}
          onClick={() => setPrints(0)}
        />
      </div>
    </DialogBox>
  );
};

export const AddMultipleInstallment = ({ dialogueData, setChoiceAddition }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    const addInstallment = submitData(e);
    if (addInstallment) {
      console.log("addInstallment", addInstallment);

      let addInstallmentData = {
        ...addInstallment,
        studentId: dialogueData._id,
      };
      try {
        let response = await dispatch(
          installmentCreateMultiple(addInstallmentData)
        ).unwrap();

        response.status
          ? dispatch(installmentGet(dialogueData._id))
          : setToast(response.message);
        setChoiceAddition(0);
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} id="installmentForm">
      <div className="showFees row align-items-center">
        <div className="col-xxl-3 col-lg-6 col-12">
          <Input
            type={`date`}
            id={`startDate`}
            name={`startDate`}
            label={`Installment Date`}
            errorMessage={`Enter Installment Date`}
          />
        </div>
        <div className="col-xxl-3 col-lg-6 col-12">
          <Input
            type={`number`}
            id={`fee`}
            name={`fee`}
            label={`Total Fees`}
            errorMessage={`Enter Fees`}
          />
        </div>
        <div className="col-xxl-3 col-lg-6 col-12">
          <Input
            type={`text`}
            id={`installmentCount`}
            name={`installmentCount`}
            label={`Installment Count`}
            errorMessage={`Enter Installment Count`}
            max={2}
            validation={`^(1[0-5]|[1-9]|0[1-9]|1[0-5]?|15)$`}
            validationError={`Max 15 Installment`}
          />
        </div>
        <div className="col-xxl-3 col-lg-6 col-12">
          <Button
            type={`submit`}
            className={`bg-second text-light border-second-light-2 `}
            icon={`ri-check-double-line`}
          />
          <Button
            className={`bg-gray2 text-darkGray border-darkGray-2 m5-left`}
            icon={`ri-close-line`}
            type={`button`}
            onClick={() => setChoiceAddition(0)}
          />
        </div>
      </div>
    </form>
  );
};

export const AddSingleInstallment = ({ dialogueData, setChoiceAddition }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    const addInstallment = submitData(e);
    if (addInstallment) {
      console.log("addInstallment", addInstallment);

      let addInstallmentData = {
        ...addInstallment,
        studentId: dialogueData._id,
      };
      try {
        let response = await dispatch(
          installmentCreateSingle(addInstallmentData)
        ).unwrap();

        response.status
          ? dispatch(installmentGet(dialogueData._id))
          : setToast(response.message);
        setChoiceAddition(0);
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} id="installmentForm">
      <div className="showFees row align-items-center">
        <div className="col-xxl-5 col-lg-6 col-12">
          <Input
            type={`date`}
            id={`startDate`}
            name={`startDate`}
            label={`Installment Date`}
            errorMessage={`Enter Installment Date`}
          />
        </div>
        <div className="col-xxl-4 col-lg-6 col-12">
          <Input
            type={`text`}
            id={`fee`}
            name={`fee`}
            label={`Installment`}
            errorMessage={`Enter Installment`}
          />
        </div>
        <div className="col-xxl-3 col-lg-6 col-12">
          <Button
            type={`submit`}
            className={`bg-second text-light border-second-light-2 `}
            icon={`ri-check-double-line`}
          />
          <Button
            className={`bg-gray2 text-darkGray border-darkGray-2 m5-left`}
            icon={`ri-close-line`}
            type={`button`}
            onClick={() => setChoiceAddition(0)}
          />
        </div>
      </div>
    </form>
  );
};

export const ShowTotals = ({ installmentCount }) => {
  return (
    <div className="showFees">
      <div className="row align-items-center">
        <div className="col-xxl-4 col-lg-6 col-12">
          <Input
            type={`number`}
            disabled={true}
            label={`Total Fee`}
            value={installmentCount?.totalInstallmentCount}
            errorMessage={`-`}
          />
        </div>
        <div className="col-xxl-4 col-lg-6 col-12">
          <Input
            type={`number`}
            disabled={true}
            label={`Complate Fee`}
            value={installmentCount?.completeInstallmentCount}
            errorMessage={`-`}
          />
        </div>
        <div className="col-xxl-4 col-lg-6 col-12">
          <Input
            type={`number`}
            disabled={true}
            label={`Pending Fee`}
            value={installmentCount?.pendingInstallmentCount}
            errorMessage={`-`}
          />
        </div>
      </div>
    </div>
  );
};
