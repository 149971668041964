import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  course: [],
  courseTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const courseGet = createAsyncThunk("course/show", async (payload) => {
  return apiInstance.get(
    `course/show?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
  );
});
export const courseAdd = createAsyncThunk("course/create", async (payload) => {
  return apiInstance.post("course/create", payload);
});
export const courseUpdate = createAsyncThunk(
  "course/update",
  async (payload) => {
    return apiInstance.patch(
      `course/update?id=${payload.id}`,
      payload.addCourse
    );
  }
);
export const courseDelete = createAsyncThunk("course/delete", async (id) => {
  return apiInstance.delete(`course/delete?id=${id}`);
});

const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // courseGet
    builder.addCase(courseGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(courseGet.fulfilled, (state, action) => {
      state.course = action.payload.course;
      state.courseTotal = action.payload.courseTotal;
      state.isSkeleton = false;
    });
    builder.addCase(courseGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // courseCreate
    builder.addCase(courseAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(courseAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.course.unshift(action.payload.course);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(courseAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // courseUpdate
    builder.addCase(courseUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(courseUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const courseIdx = state.course.findIndex(
          (course) => course._id === action.payload.course._id
        );
        if (courseIdx !== -1) {
          state.course[courseIdx] = {
            ...state.course[courseIdx],
            ...action.payload.course,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(courseUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // courseDelete
    builder.addCase(courseDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(courseDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.course = state.course.filter(
          (course) => course._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(courseDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default courseSlice.reducer;
