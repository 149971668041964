import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { Textarea } from "../../../extra/Input";
import Button, { ActionButton } from "../../../extra/Button";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { addSalaryPenaltyBonus } from "../../../../redux/slice/staffSalarySlice";
import DialogBox from "../../../extra/DialogBox";

const AddPenaltyBonus = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [isAction, setIsAction] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setIsAction(dialogueData?.isAction);
      editData(dialogueData);
    }
  }, [dialogueData]);
  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData, isAction]);

  const handleSubmit = async (e) => {
    const addpenaltyBonusData = submitData(e);
    if (addpenaltyBonusData) {
      let payload = {
        ...addpenaltyBonusData,
        isAction,
        staffSalaryId: dialogueData._id,
      };
      try {
        let response = await dispatch(addSalaryPenaltyBonus(payload)).unwrap();
        console.log(response.status, "response.data.status");
        response?.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`staffSalaryForm`}
      title={`Staff Salary Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody p50-bottom">
        <div className="col-lg-6 col-12">
          <div className="inputData">
            <label>Penalty/Bonus</label>
          </div>
          <div className="masterButton m40-bottom">
            <ActionButton
              type={`button`}
              className={`leaveType fs-14 bg-second text-light border-second-2 m10-right m5-top`}
              icon={`ri-close-circle-line`}
              onClick={() => setIsAction("")}
            />
            <ActionButton
              type={`button`}
              className={`leaveType fs-14 ${
                isAction == "bonus"
                  ? "bg-success text-light"
                  : "bg-success-light text-success"
              }  border-success-2 m10-right m5-top`}
              text={`Bonus`}
              onClick={() => setIsAction("bonus")}
            />
            <ActionButton
              type={`button`}
              className={`leaveType fs-14 ${
                isAction == "penalty"
                  ? "bg-danger text-light"
                  : "bg-danger-light text-danger"
              }  border-danger-2 m10-right m5-top`}
              text={`Penalty`}
              onClick={() => setIsAction("penalty")}
            />
          </div>
        </div>
        <div className="col-lg-6 col-12">
          {isAction == "bonus" && (
            <Input
              type={`text`}
              id={`bonus`}
              name={`bonus`}
              label={`Bonus`}
              errorMessage={`Enter Bonus`}
            />
          )}
          {isAction == "penalty" && (
            <Input
              type={`text`}
              id={`penalty`}
              name={`penalty`}
              label={`Penalty`}
              errorMessage={`Enter Penalty`}
            />
          )}
        </div>
        <div className="col-12">
          <Textarea
            row={4}
            type={`text`}
            id={`description`}
            name={`description`}
            label={`Description`}
            errorMessage={`Enter Description`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default AddPenaltyBonus;
