import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  attendance: [],
  attendanceYear: [],
  attendanceTotal: 0,
  attendanceInfo: [],
  attendanceDash: {},
  isLoading: false,
  isSkeleton: false,
  isRepete: false,
};
export const studentAttendanceGet = createAsyncThunk(
  "studentAttendance/show",
  async (payload) => {
    return apiInstance.get(`studentAttendance/show?thisYear=${payload}`);
  }
);
export const studentAttendanceGetInfo = createAsyncThunk(
  "studentAttendance/showStudentAllAttendance",
  async (payload) => {
    return apiInstance.get(
      `studentAttendance/showStudentAllAttendance?studentId=${payload}`
    );
  }
);
export const attendanceAdd = createAsyncThunk(
  "studentAttendance/create",
  async (payload) => {
    return apiInstance.post("studentAttendance/create", payload);
  }
);

export const attendanceSingleAdd = createAsyncThunk(
  "studentAttendance/createSingleAttendance",
  async (payload) => {
    return apiInstance.post(
      "studentAttendance/createSingleAttendance",
      payload
    );
  }
);
export const attendanceUpdate = createAsyncThunk(
  "studentAttendance/update",
  async (payload) => {
    return apiInstance.put(
      `studentAttendance/update?studentAttendanceId=${payload.attendanceId}&status=${payload.status}`
    );
  }
);
export const attendanceDelete = createAsyncThunk(
  "studentAttendance/delete",
  async (payload) => {
    return apiInstance.delete(
      `studentAttendance/delete?studentAttendanceId=${payload}`
    );
  }
);
export const attendanceThisMonthDelete = createAsyncThunk(
  "studentAttendance/deleteThisMonthAttendance",
  async (payload) => {
    return apiInstance.delete(
      `studentAttendance/deleteThisMonthAttendance?year=${payload.year}&month=${payload.month}`
    );
  }
);

const studentAttendanceSlice = createSlice({
  name: "studentAttendanceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // studentAttendanceGet
    builder.addCase(studentAttendanceGet.pending, (state, action) => {
      // state.isSkeleton = action.meta.arg.command;
      state.isRepete = true;
    });
    builder.addCase(studentAttendanceGet.fulfilled, (state, action) => {
      state.attendance = action.payload.studentAttendance;
      state.attendanceYear = action.payload.allYears;
      // state.attendanceTotal = action.payload.attendanceTotal;
      state.isSkeleton = false;
      state.isRepete = true;
    });
    builder.addCase(studentAttendanceGet.rejected, (state, action) => {
      state.isSkeleton = false;
      state.isRepete = true;
    });
    // studentAttendanceGetInfo
    builder.addCase(studentAttendanceGetInfo.pending, (state, action) => {
      // state.isSkeleton = action.meta.arg.command;
      state.isRepete = true;
    });
    builder.addCase(studentAttendanceGetInfo.fulfilled, (state, action) => {
      const {
        totalAttendance,
        totalMonthCount,
        totalPresent,
        totalAbsent,
        totalLate,
        totalHoliday,
      } = action?.payload?.studentAttendance;

      state.attendanceInfo = totalAttendance;
      const dashData = {
        totalMonthCount,
        totalPresent,
        totalAbsent,
        totalLate,
        totalHoliday,
      };
      state.attendanceDash = dashData;
      state.isSkeleton = false;
      state.isRepete = true;
    });
    builder.addCase(studentAttendanceGetInfo.rejected, (state, action) => {
      state.isSkeleton = false;
      state.isRepete = true;
    });

    // attendanceCreate
    builder.addCase(attendanceAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(attendanceAdd.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(attendanceAdd.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default studentAttendanceSlice.reducer;
