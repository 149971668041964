import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Pagination from "../../../extra/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../util/Alert";
import {
  studentHolidayDelete,
  studentHolidayGet,
} from "../../../../redux/slice/studentHolidaySlice";
import StudentHolidayAdd from "./StudentHolidayAdd";
import { Select } from "../../../extra/Input";
const StudentHoliday = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");
  const studentLeaveYear = ["2024", "2025"];

  const { studentHoliday } = useSelector((state) => state.studentHoliday);

  // Pagination BOTH

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(studentHolidayGet(years));
  }, [years]);
  useEffect(() => {
    dispatch(studentHolidayGet(years));
  }, []);

  useEffect(() => {
    setData(studentHoliday);
  }, [studentHoliday]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(studentHolidayDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const holidayTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Holiday Date",
      body: "holidayDate",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Day",
      body: "holidayDay",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Month",
      body: "month",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Description",
      body: "description",
      sorting: { type: "client" },
      thClass: "justify-content-center",
      thClass: "justify-content-center",
      tdClass: "text-center  text-capitalize",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray  border-darkGray-1  m5-right"
            onClick={() =>
              dispatch(openDialog({ type: "studentHoliday", data: row }))
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-second-light text-second border-second-1  m5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
  ];

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-3 col-sm-6 col-12">
          <Title name={"student holiday"} icon={`ri-calendar-event-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={studentHoliday}
              setData={setData}
              column={holidayTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={studentLeaveYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-darkGray-2 m20-left`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "studentHoliday" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={holidayTable}
        PerPage={rowsPerPage}
        Page={page}
      />
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data.length}
      />
      {dialogue && dialogueType === "studentHoliday" && <StudentHolidayAdd />}
    </div>
  );
};

export default StudentHoliday;
